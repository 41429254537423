<template>
  <div>
    <div class="page-title">外延片库存管理</div>
    <!-- 选项 -->
    <div style="float:left;margin-top: 1rem;width: 100%">
      <el-col :span="24">
        <el-tooltip content="库状态" placement="top" transition="none" effect="light" style="width: 80px">
          <el-select
            placeholder="库状态"
            v-model="colScreen.库状态"
            clearable
            allow-create
            filterable
            default-first-option
            size="mini"
            multiple
            collapse-tags
          >
            <el-option value="<排除>" label="<排除>"></el-option>
            <el-option value="<空白>" label="<空白>"></el-option>
            <el-option v-for="item in ['I','O','W','T']" :value="item">{{ item }}</el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="机台" placement="top" transition="none" effect="light" style="width: 80px">
          <el-select
            placeholder="机台"
            v-model="colScreen.机台"
            clearable
            allow-create
            filterable
            default-first-option
            size="mini"
          >
            <el-option v-for="item in 候选项目.机台" :value="item">{{ item }}</el-option>
          </el-select>
        </el-tooltip>
        <!--
        <el-tooltip content="RUN ID" placement="top" transition="none" effect="light" style="width: 110px">
          <el-input
            size="mini"
            placeholder="RUN ID"
            v-model="colScreen.RUN_ID"
          ></el-input>
        </el-tooltip>
        <el-tooltip content="Wafer ID" placement="top" transition="none" effect="light" style="width: 110px">
          <el-input
            size="mini"
            placeholder="Wafer ID"
            v-model="colScreen.Wafer_ID"
          ></el-input>
        </el-tooltip>
        -->
        <el-tooltip content="生产日期" placement="bottom" transition="none" effect="light" style="width: 155px">
          <el-date-picker
            v-model="colScreen.生产日期"
            type="daterange"
            range-separator="至"
            placeholder="生产日期"
            size="mini"
          >
          </el-date-picker>
        </el-tooltip>
        <el-tooltip content="入库日期" placement="bottom" transition="none" effect="light" style="width: 155px">
          <el-date-picker
            v-model="colScreen.入库日期"
            type="daterange"
            range-separator="至"
            placeholder="入库日期"
            size="mini"
          >
          </el-date-picker>
        </el-tooltip>
        <el-tooltip content="挑片日期" placement="bottom" transition="none" effect="light" style="width: 155px">
          <el-date-picker
            v-model="colScreen.挑片日期"
            type="daterange"
            range-separator="至"
            placeholder="挑片日期"
            size="mini"
          >
          </el-date-picker>
        </el-tooltip>
        <el-tooltip content="出库日期" placement="bottom" transition="none" effect="light" style="width: 155px">
          <el-date-picker
            v-model="colScreen.出库日期"
            type="daterange"
            range-separator="至"
            placeholder="出库日期"
            size="mini"
          >
          </el-date-picker>
        </el-tooltip>
        <el-tooltip content="筛选项" placement="bottom" transition="none" effect="light" style="width: 100px">
          <el-select
            placeholder="筛选项"
            clearable
            filterable
            v-model="colScreen.筛选项"
            size="mini"
          >
            <el-option
              v-for="item in ['Wafer ID', '激光编码', '出库流向', '目检备注', '生产类型', '类型', '厂商']"
              :value="item"
            >
              {{ item }}
            </el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip content="关键字" placement="bottom" transition="none" effect="light" style="width: 130px">
          <el-input
            placeholder="关键字"
            clearable
            v-model="colScreen.关键字"
            @keydown.native.enter="GetExSku"
            size="mini"
          ></el-input>
        </el-tooltip>
        <el-button type="primary" plain @click="GetExSku" size="mini">开始筛选</el-button>
      </el-col>
    </div>
    <!-- 按钮 -->
    <div style="float:left;margin-top: 1rem;width:100%">
      <el-button size="mini" type="danger" plain @click="InLayer">导入</el-button>
      <el-button size="mini" type="danger" plain @click="BuyIn">导入外购数据</el-button>
      <el-button size="mini" type="success" plain @click="Output">导出</el-button>
      <el-button size="mini" type="danger" plain @click="RwLayer">覆盖导入</el-button>
      <el-button size="mini" type="primary" plain @click="BatLayer">导入-批量修改</el-button>
      <el-button size="mini" type="primary" plain @click="BsLayer(false)">选中-批量修改</el-button>
      <el-button size="mini" type="success" plain @click="AllPage">全选本页</el-button>
      <el-button size="mini" type="danger" @click="DelLayer(false)">删除所选</el-button>
      <el-button size="mini" type="danger" plain @click="BsClear">取消已选</el-button>
      <el-button size="mini" type="warning" plain @click="ClearAll">清空选项</el-button>
    </div>
    <!-- 主表格 -->
    <div style="float:left;width:100%;margin-top:1rem">
      <pl-table
        use-virtual
        class="tb-edit"
        :data="extSkuData.data"
        border
        @row-click="RowSelect"
        @row-dblclick="RowDblClick"
        ref="multipleTable"
        :row-class-name="GetRowClassName"
        height="638"
        :cell-style="{padding:0,height:'28px','text-align':'center'}"
        :row-height="28"
        :header-cell-style="{padding:0,height:'68px'}"
        @row-contextmenu="RightClick"
      >
        <pl-table-column label="公司" prop="公司" width="80px">
          <template slot="header">
            <el-tooltip content="公司" effect="light" placement="top" transition="none" style="width:100%">
              <el-select
                v-model="colScreen.公司"
                placeholder="公司"
                size="mini"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
              >
                <el-option v-for="item in 候选项目.公司" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="生产日期" label="生产日期" width="100">
          <!--
          <template slot="header">
            <el-tooltip content="生产日期" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="生产日期"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.生产日期"
              >
                <el-option v-for="item in 候选项目.生产日期" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
          -->
        </pl-table-column>
        <pl-table-column prop="机台" label="机台" width="80">
          <template slot="header">
            <el-tooltip content="机台" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="机台"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.机台"
              >
                <el-option v-for="item in 候选项目.机台" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="结构" label="结构" width="80">
          <template slot="header">
            <el-tooltip content="结构" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="结构"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.结构"
                multiple
                collapse-tags
              >
                <el-option value="<排除>" label="<排除>"></el-option>
                <el-option value="<空白>" label="<空白>"></el-option>
                <el-option
                  v-for="item in 候选项目.结构"
                  :value="item"
                >{{ item }}
                </el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="RUN_ID" label="RUN ID" width="120">
          <template slot="header">
            <el-tooltip content="RUN ID" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                v-model="colScreen.RUN_ID"
                placeholder="RUN ID"
                size="mini"
                clearable
                filterable
                allow-create
                default-first-option
                @change="GetExSku()"
              >
                <el-option
                  v-for="item in 候选项目.RUN_ID"
                  :value="item"
                >{{ item }}
                </el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="Wafer_ID" label="Wafer ID" width="120">
          <template slot="header">
            <el-tooltip content="Wafer ID" placement="top" transition="none" effect="light" style="width:100%">
              <el-input
                size="mini"
                clearable
                placeholder="Wafer ID"
                v-model="colScreen.Wafer_ID"
                @keydown.native.enter="GetExSku()"
              />
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="激光编码" label="激光编码" width="120">
          <template slot="header">
            <el-tooltip content="激光编码" placement="top" transition="none" effect="light" style="width:100%">
              <el-input
                size="mini"
                clearable
                placeholder="激光编码"
                v-model="colScreen.激光编码"
                @keydown.native.enter="GetExSku()"
              />
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="厂商" label="厂商" width="120">
          <template slot="header">
            <el-tooltip content="厂商" placement="top" transition="none" effect="light" style="width:100%">
              <el-input
                size="mini"
                clearable
                placeholder="厂商"
                v-model="colScreen.厂商"
                @keydown.native.enter="GetExSku()"
              />
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="圈" label="圈" width="80">
          <template slot="header">
            <el-tooltip content="圈" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="圈"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.圈"
              >
                <el-option v-for="item in 候选项目.圈" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="衬底尺寸" label="衬底尺寸" width="80">
          <template slot="header">
            <el-tooltip content="衬底尺寸" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="衬底尺寸"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.衬底尺寸"
              >
                <el-option v-for="item in 候选项目.衬底尺寸" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLP" label="WLP" width="100">
          <template slot="header">
            <el-tooltip content="WLP" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="WLP"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.WLP"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="STD" label="STD" width="100">
          <template slot="header">
            <el-tooltip content="STD" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="STD"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.STD"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="Dom_Lambda" label="Dom Lambda" width="110">
          <template slot="header">
            <el-tooltip content="Dom Lambda" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="Dom Lambda"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.Dom_Lambda"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="lnt" label="lnt" width="100">
          <template slot="header">
            <el-tooltip content="lnt" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="lnt"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.lnt"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="FWHM" label="FWHM" width="110">
          <template slot="header">
            <el-tooltip content="FWHM" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="FWHM"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.FWHM"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="表面等级" label="表面等级" width="100">
          <template slot="header">
            <el-tooltip content="表面等级" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="表面等级"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.表面等级"
                multiple
                collapse-tags
              >
                <el-option value="<排除>" label="<排除>"></el-option>
                <el-option value="<空白>" label="<空白>"></el-option>
                <el-option v-for="item in 候选项目.表面等级" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="目检备注" label="目检备注" width="120">
          <template slot="header">
            <el-tooltip content="目检备注" placement="top" transition="none" effect="light">
              <el-input
                placeholder="目检备注"
                size="mini"
                clearable
                v-model="colScreen.目检备注"
                @keydown.native.enter="GetExSku()"
              />
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            {{ (scope.row.目检备注 || '').split('\n').reverse().join('\n') }}
          </template>
        </pl-table-column>
        <pl-table-column prop="衬底规格" label="衬底规格" width="100">
          <template slot="header">
            <el-tooltip content="衬底规格" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="衬底规格"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.衬底规格"
              >
                <el-option v-for="item in 候选项目.衬底规格" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="报废判定" label="报废判定" width="110">
          <template slot="header">
            <el-tooltip content="报废判定" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="报废判定"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.报废判定"
              >
                <el-option v-for="item in 候选项目.报废判定" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="生产类型" label="生产类型" width="110">
          <template slot="header">
            <el-tooltip content="生产类型" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="生产类型"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.生产类型"
                multiple
                collapse-tags
              >
                <el-option value="<排除>" label="<排除>"></el-option>
                <el-option value="<空白>" label="<空白>"></el-option>
                <el-option v-for="item in 候选项目.生产类型" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="入库日期" label="入库日期" width="110">
          <!--
          <template slot="header">
            <el-tooltip content="入库日期" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="入库日期"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.入库日期"
              >
                <el-option v-for="item in 候选项目.入库日期" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
          -->
        </pl-table-column>
        <pl-table-column prop="库状态" label="库状态" width="100">
          <template slot="header">
            <el-tooltip content="库状态" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="库状态"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.库状态"
                multiple
                collapse-tags
              >
                <el-option value="<排除>" label="<排除>"></el-option>
                <el-option value="<空白>" label="<空白>"></el-option>
                <el-option v-for="item in ['I','O','W','T']" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="库存位置" label="库存位置" width="110">
          <template slot="header">
            <el-tooltip content="库存位置" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="库存位置"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.库存位置"
              >
                <el-option v-for="item in 候选项目.库存位置" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="推荐版型" label="推荐版型" width="110">
          <template slot="header">
            <el-tooltip content="推荐版型" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="推荐版型"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.推荐版型"
              >
                <el-option v-for="item in 候选项目.推荐版型" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="波段" label="波段" width="120">
          <template slot="header">
            <el-tooltip content="波段" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="波段"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.波段"
              >
                <el-option v-for="item in 候选项目.波段" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="综合等级" label="综合等级" width="110">
          <template slot="header">
            <el-tooltip content="综合等级" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="综合等级"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.综合等级"
              >
                <el-option v-for="item in 候选项目.综合等级" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="版型" label="版型" width="100">
          <template slot="header">
            <el-tooltip content="版型" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="版型"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.版型"
              >
                <el-option v-for="item in 候选项目.版型" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="类型" label="类型" width="100">
          <template slot="header">
            <el-tooltip content="类型" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="类型"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.类型"
                multiple
                collapse-tags
              >
                <el-option value="<排除>" label="<排除>"></el-option>
                <el-option value="<空白>" label="<空白>"></el-option>
                <el-option v-for="item in 候选项目.类型" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="挑片日期" label="挑片日期" width="110">
          <!--
          <template slot="header">
            <el-tooltip content="挑片日期" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="挑片日期"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.挑片日期"
              >
                <el-option v-for="item in 候选项目.挑片日期" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
          -->
        </pl-table-column>
        <pl-table-column prop="出库日期" label="出库日期" width="110">
          <!--
          <template slot="header">
            <el-tooltip content="出库日期" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="出库日期"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.出库日期"
              >
                <el-option v-for="item in 候选项目.出库日期" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
          -->
        </pl-table-column>
        <pl-table-column prop="出库流向" label="出库流向" width="110">
          <template slot="header">
            <el-tooltip content="出库流向" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="出库流向"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.出库流向"
              >
                <el-option v-for="item in 候选项目.出库流向" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="返回日期" label="返回日期" width="105">
          <template slot="header">
            <el-tooltip content="返回日期" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="返回日期"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.返回日期"
              >
                <el-option v-for="item in 候选项目.返回日期" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="返回周期" label="返回周期"></pl-table-column>
        <pl-table-column prop="返回状态" label="返回状态" width="80">
          <template slot="header">
            <el-tooltip content="返回状态" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="返回状态"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.返回状态"
              >
                <el-option v-for="item in 候选项目.返回状态" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="VF" label="VF" width="90">
          <template slot="header">
            <el-tooltip content="VF" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="VF"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.VF"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="IR" label="IR" width="90">
          <template slot="header">
            <el-tooltip content="IR" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="IR"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.IR"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="I" label="I" width="90">
          <template slot="header">
            <el-tooltip content="I" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="I"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.I"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="Ee" label="Ee" width="90">
          <template slot="header">
            <el-tooltip content="Ee" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="Ee"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.Ee"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="IP" label="IP" width="90">
          <template slot="header">
            <el-tooltip content="IP" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="IP"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.IP"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLP1" label="WLP1" width="100">
          <template slot="header">
            <el-tooltip content="WLP1" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="WLP1"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.WLP1"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLP_STD" label="WLP STD" width="120">
          <template slot="header">
            <el-tooltip content="WLP STD" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="WLP STD"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.WLP_STD"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WLD" label="WLD" width="100">
          <template slot="header">
            <el-tooltip content="WLD" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="WLD"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.WLD"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="WD_STD" label="WD STD" width="120">
          <template slot="header">
            <el-tooltip content="WD STD" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="WD STD"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.WD_STD"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="HW" label="HW" width="100">
          <template slot="header">
            <el-tooltip content="HW" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="HW"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.HW"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="色纯度" label="色纯度" width="100">
          <template slot="header">
            <el-tooltip content="色纯度" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="色纯度"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.色纯度"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="翘曲" label="翘曲" width="100">
          <template slot="header">
            <el-tooltip content="翘曲" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="翘曲"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.翘曲"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="厚度" label="厚度" width="100">
          <template slot="header">
            <el-tooltip content="厚度" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="厚度"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.厚度"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="厚度std" label="厚度std" width="110">
          <template slot="header">
            <el-tooltip content="厚度std" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="厚度std"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.厚度std"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="Bulk_Concentration" label="Bulk Concentration" width="130">
          <template slot="header">
            <el-tooltip content="Bulk Concentration" placement="top" transition="none" effect="light"
                        style="width:100%">
              <el-select
                size="mini"
                placeholder="Bulk Concentration"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.Bulk_Concentration"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="Sheet_Concentration" label="Sheet Concentration" width="130">
          <template slot="header">
            <el-tooltip content="Sheet Concentration" placement="top" transition="none" effect="light"
                        style="width:100%">
              <el-select
                size="mini"
                placeholder="Sheet Concentration"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.Sheet_Concentration"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="Mobility" label="Mobility" width="120">
          <template slot="header">
            <el-tooltip content="Mobility" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="Mobility"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.Mobility"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="Resistivity" label="Resistivity" width="130">
          <template slot="header">
            <el-tooltip content="Resistivity" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="Resistivity"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.Resistivity"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="XRD102" label="XRD102" width="120">
          <template slot="header">
            <el-tooltip content="XRD102" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="XRD102"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.XRD102"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="XRD002" label="XRD002" width="120">
          <template slot="header">
            <el-tooltip content="XRD002" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="XRD002"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.XRD002"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="备用列1" label="备用列1" width="120">
          <template slot="header">
            <el-tooltip content="备用列1" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="备用列1"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.备用列1"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
        <pl-table-column prop="备用列2" label="备用列2" width="120">
          <template slot="header">
            <el-tooltip content="备用列2" placement="top" transition="none" effect="light" style="width:100%">
              <el-select
                size="mini"
                placeholder="备用列2"
                filterable
                allow-create
                default-first-option
                clearable
                @change="GetExSku()"
                v-model="colScreen.备用列2"
              >
                <el-option v-for="item in []" :value="item">{{ item }}</el-option>
              </el-select>
            </el-tooltip>
          </template>
        </pl-table-column>
      </pl-table>
      <el-pagination
        style="margin-top:1rem"
        @current-change="PageChange"
        :current-page="extSkuData.page+1"
        :page-size="extSkuData.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="extSkuData.count">
      </el-pagination>
    </div>
    <!-- 新数据导入层 -->
    <el-dialog
      title="导入外延片库存"
      :visible.sync="inLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :action="inUrl"
        :on-success="function(res){return InAfterU(res)}"
        :before-upload="function(file){ return InBeforeU(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <div v-show="step2">
        <ex-table my-name="insTable" ref="insTable" :table-data="insConfirm.data" @change-screen="GetSubScreen"/>
      </div>
      <div style="width: 100%;float:left;margin:0.5rem 0" v-show="step2">
        <el-pagination
          @current-change="InPageChg"
          :current-page="insConfirm.page+1"
          :page-size="insConfirm.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="insConfirm.count">
        </el-pagination>
      </div>
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="step2">共 {{ insConfirm.count }} 条记录</span>
        <el-button type="primary" v-show="step2" @click="InDo" plain>确认导入</el-button>
        <el-button @click="InCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 覆盖导入层 -->
    <el-dialog
      title="覆盖导入外延片库存"
      :visible.sync="rwLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <span
        v-show="step1"
        style="font-size:1.2rem;font-weight:520;padding-bottom:1rem;float:left;width: 100%"
      >请上传包含覆盖数据的表格文件</span>
      <el-upload
        ref="rwUpload"
        class="upload-demo"
        drag
        :action="rwUrl"
        :on-success="function(res){return RwAfterU(res)}"
        :before-upload="function(file){ return RwBeforeU(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
        style="margin-left:30px"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <!-- 覆盖前确认数据表 -->
      <div v-show="step2">
        <ex-table my-name="rwTable" ref="rwTable" :table-data="rwConfirm.data" @change-screen="GetSubScreen"/>
      </div>
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="step2">
          共 {{ rwConfirm.data.length }} 条记录
        </span>
        <el-button type="warning" v-show="step2" @click="RwON" plain style="margin-left:150px">
          切换为{{ rwConfirm.data === rwOldData ? '新' : '旧' }}数据
        </el-button>
        <el-button type="danger" v-show="step2" @click="RwDo" plain>确定覆盖</el-button>
        <el-button @click="RwCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 批量修改层 -->
    <el-dialog
      title="批量修改外延片库存"
      :visible.sync="batLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <span
        v-show="step1"
        style="font-size:1.2rem;font-weight:520;padding-bottom:1rem;float:left;width: 100%"
      >请上传批量修改的表格数据文件</span>
      <el-upload
        ref="uploadBat"
        class="upload-demo"
        drag
        :action="batUrl"
        :on-success="function(res){return BatAfterU(res)}"
        :before-upload="function(file){ return BatBeforeU(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
        style="margin-left:30px"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <!-- 批量修改确认数据表 -->
      <div v-show="step2">
        <ex-table my-name="batTable" ref="batTable" :table-data="batConfirm.data" @change-screen="GetSubScreen"/>
      </div>
      <div style="width: 100%;float:left;margin:0.5rem 0" v-show="step2">
        <el-pagination
          @current-change="BatPageChg"
          :current-page="batConfirm.page"
          :page-size="batConfirm.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="batConfirm.count">
        </el-pagination>
      </div>
      <!-- 批量修改表单 -->
      <ext-form
        v-show="step3"
        :ext-form-data="{}"
        :option-list="候选项目"
        ref="batForm"
        form-name="bat"
        @emit-form-data="GetFormData"
      />
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="step2">
          共 {{ batConfirm.count }} 条记录
        </span>
        <el-button type="primary" v-show="step2" @click="BatNext" plain>下一步</el-button>
        <el-button type="danger" v-show="step3" @click="BatDo" plain style="margin-left:150px">批量修改</el-button>
        <el-button @click="BatCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 批量选中修改层 -->
    <el-dialog
      title="修改选中的外延片库存"
      :visible.sync="bsLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <!-- 批量选中确认数据表 -->
      <div v-show="step1">
        <ex-table my-name="bsTable" ref="bsTable" :table-data="bsConfirm" @change-screen="GetSubScreen"/>
      </div>
      <!-- 批量修改表单 -->
      <ext-form
        v-show="step2"
        ref="bsForm"
        :ext-form-data="{}"
        :option-list="候选项目"
        form-name="bs"
        @emit-form-data="GetFormData"
      />
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="step1">
          共 {{ bsConfirm.length }} 条记录
        </span>
        <el-button type="primary" v-show="step1" @click="BsNext" plain>下一步</el-button>
        <el-button type="danger" v-show="step2" @click="BsDo" plain style="margin-left:150px">批量修改</el-button>
        <el-button @click="BsCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 批量选中删除层 -->
    <el-dialog
      title="删除选中的外延片库存"
      :visible.sync="delLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <!-- 批量选中确认数据表 -->
      <div v-show="step1">
        <ex-table my-name="delTable" ref="delTable" :table-data="bsConfirm" @change-screen="GetSubScreen"/>
      </div>
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="step1">
          共 {{ bsConfirm.length }} 条记录
        </span>
        <el-button type="danger" v-show="step1" @click="DelDo">确认删除</el-button>
        <el-button @click="DelCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 单条数据的修改 -->
    <el-dialog
      :title="whichEdit.Wafer_ID + '单条外延片数据修改'"
      :visible.sync="oneLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
    >
      <ext-form
        ref="oneRow"
        :ext-form-data="whichEdit"
        :option-list="候选项目"
        form-name="oneRow"
        @emit-form-data="GetFormData"
      />
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <el-button type="danger" @click="RowEdit" style="margin-left:150px">确认修改</el-button>
        <el-button @click="oneLayer=false" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 右键菜单 -->
    <div id="contextmenu" v-show="menuVisible" class="menu">
      <div class="contextmenu__item" @click="RCEditRow">单行修改</div>
      <div class="contextmenu__item" @click="ShowLogs">操作日志</div>
      <div class="contextmenu__item" @click="Cancel">取消</div>
    </div>
    <!-- 显示外延日志 -->
    <el-dialog
      :title="whichRightData.Wafer_ID+'操作日志'"
      :visible.sync="exLogs"
      :close-on-click-modal="true"
      :lock-scroll="true"
      :append-to-body="true"
      width="660px"
    >
      <!-- 时间轴 -->
      <el-timeline>
        <el-timeline-item v-for="log in showExLogs" :timestamp="log.操作时间" placement="top">
          <strong>{{ log.操作事项 }}</strong>
          <div>操作人：{{ log.操作人 }}</div>
          <div v-html="log.操作内容.replace(/\n/g, '<br/>')"></div>
        </el-timeline-item>
      </el-timeline>
      <!-- 底部按钮 -->
      <div style="margin-top:30px">
        <el-button style="margin-left:50px" @click="exLogs=false" type="danger" plain>关闭</el-button>
      </div>
    </el-dialog>
    <!-- 外购 -->
    <el-dialog
      title="导入外购的外延片库存"
      :visible.sync="buyInLayer"
      fullscreen
      :close-on-click-modal="true"
      :lock-scroll="true"
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :action="IBUrl"
        :on-success="function(res){return IBover(res)}"
        :before-upload="function(file){return IBBefore(file)}"
        :data="{token}"
        :headers="{token}"
        :show-file-list="false"
        v-show="step1"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">1. 只能上传 xlsx 格式的文件</div>
        <div class="el-upload__tip" slot="tip">2. 上传前请确保表格的标题行内容准确无误</div>
        <div class="el-upload__tip" slot="tip">3. 上传完成后，程序将只导入第 1 张工作表的数据</div>
      </el-upload>
      <div v-show="step2">
        <ex-table my-name="inIB" ref="inIB" :table-data="insConfirm.data"/>
      </div>
      <ext-form
        v-show="step3"
        :ext-form-data="buyForm"
        :option-list="候选项目"
        ref="bForm"
        form-name="buy"
        @emit-form-data="GetFormData"
      />
      <div style="margin-top:30px">
        <span style="padding:0 0.5rem" v-show="step2">共 {{ insConfirm.data.length }} 条记录</span>
        <el-button type="warning" v-show="step2" plain @click="step3=true;step1=step2=false">下一步</el-button>
        <el-button type="warning" v-show="step3" plain @click="step2=true;step1=step3=false">上一步</el-button>
        <el-button type="primary" v-show="step3" @click="IBDo" plain>确认导入</el-button>
        <el-button @click="InCl" type="danger" plain>取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {baseTitle, baseUrl} from "../configs/squareConfig";
import {
  cancelExIns, exBatCl, exBatDo, exBsDel, exBsDo,
  exDoIns, exEditRow, exGetBat, exGetBs, exRewriteCl, exRewriteDo,
  getExCache, getExLogs,
  getExSkuData, insBuyEpi
} from "../http/api";
import ExTable from "../components/ExTable";
import ExtForm from "../components/ExtForm";
import {Loading} from 'element-ui'
import {formRequest} from "../http/formRequest";
import moment from "moment";

let loading
export default {
  name: "ProdExtSku",
  components: {ExtForm, ExTable},
  data() {
    return {
      token: this.$cookies.get('token'),
      step1: true,
      step2: false,
      step3: false,
      page: 1,
      extSkuData: [],    // 外延片库存数据列表
      colScreen: {},     // 主表单筛选项
      subTableData: [],  // 子组件表格的临时数据
      subScreen: {},     // 子组件筛选项
      inLayer: false,
      inUrl: baseUrl + '/ex-ins-upload',
      insConfirm: {
        page: 1,
        pageSize: 1000,
        count: 0,
        data: [],
      },
      rwLayer: false,
      rwUrl: baseUrl + '/ex-rw-upload',
      rwConfirm: {
        page: 1,
        pageSize: 1000,
        count: 0,
        data: [],
      },
      rwOldData: {
        page: 1,
        pageSize: 1000,
        count: 0,
        data: [],
      },
      rwNewData: {
        page: 1,
        pageSize: 1000,
        count: 0,
        data: [],
      },
      batLayer: false,
      batUrl: baseUrl + '/ex-bat-upload',
      batConfirm: {
        page: 1,
        pageSize: 1000,
        count: 0,
        data: [],
      },
      batFormData: {},
      selectedIds: [],
      bsLayer: false,
      bsConfirm: [],
      bsFormData: {},
      delLayer: false,
      whichEdit: {},
      saveRow: {},
      oneLayer: false,
      /* 右键菜单相关 */
      menuVisible: false,
      whichRightData: {},
      exLogs: false,
      showExLogs: [],
      /* 一些筛选项目的候选 */
      候选项目: {},
      buyInLayer: false,
      IBUrl: baseUrl + '/epi-ib-upload',
      buyForm: {
        生产类型: '外购',
      },
    }
  },
  methods: {
    /** 获取外延库存数据 **/
    GetExSku(page) {
      if (isNaN(page)) page = 0
      const token = this.token
      const colScreen = this.colScreen
      getExSkuData({token, colScreen, page})
        .then(res => {
          const data = res.data.data
          /* 日期的格式化 */
          data.forEach((row, index) => {
            row.生产日期 = this.GetDate(row.生产日期)
            row.挑片日期 = this.GetDate(row.挑片日期)
            row.入库日期 = this.GetDate(row.入库日期)
            row.出库日期 = this.GetDate(row.出库日期)
            row.返回日期 = this.GetDate(row.返回日期)
            data[index] = row
          })
          const keys = [
            '公司', '机台', '结构',
            '圈', '衬底尺寸','厂商',
            '表面等级', '衬底规格',
            '报废判定', '生产类型',
            '库存位置', '推荐版型',
            '综合等级', '版型',
            '类型', '出库流向',
            '生产日期', '入库日期',
            '挑片日期', '出库日期',
            '返回日期', '库状态',
            'RUN_ID', '返回状态',
            '波段',
          ]
          keys.forEach(key => {
            if (!this.候选项目[key] || key === 'RUN_ID') this.候选项目[key] = []
          })
          data.forEach(row => {
            keys.forEach(key => {
              const idx = this.候选项目[key].findIndex(item => item === row[key])
              if (idx === -1 && row[key]) this.候选项目[key].push(row[key])
            })
          })
          keys.forEach(key => this.候选项目[key].sort())
          keys.forEach(key => {
            if (key.indexOf('日期') !== -1) this.候选项目[key].reverse()
          })
          res.data.data = data
          this.extSkuData = res.data
        })
        .catch(err => console.log(err))
    },
    PageChange(e) {
      let page = e
      page = ~~page
      this.page = page
      this.GetExSku(page - 1)
    },
    /** 主表格的事件 **/
    RowSelect(row) {     // 主表格 - 单击触发 选中行事件
      const id = row.id
      const idx = this.selectedIds.findIndex(item => item === id)
      if (idx === -1) this.selectedIds.push(id)
      else this.selectedIds.splice(idx, 1)
      this.$refs.multipleTable.setCurrentRow(-1)
    },
    RowDblClick(row) {   // 主表格 - 双击触发
      this.whichEdit = JSON.parse(JSON.stringify(row))
      this.oneLayer = true
    },
    RowEdit() {
      this.$refs.oneRow.EmitFormData()
      const row = this.whichEdit
      this.whichEdit = {}
      exEditRow({
        token: this.token,
        row,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.oneLayer = false
            if (row.追加目检备注) row.目检备注 = row.目检备注 + '\n' + row.追加目检备注
            const idx = this.extSkuData.data.findIndex(item => item.id === row.id)
            if (idx !== -1) this.extSkuData.data.splice(idx, 1, row)
            // this.extSkuData.data[idx] = row /* 无法被监听 */
          }
        })
        .catch(err => console.log(err))
    },
    /** 新数据导入 **/
    InLayer() {     // 新数据 导入弹窗
      this.inLayer = this.step1 = true
      this.step2 = this.step3 = false
      if (this.$refs.upload) this.$refs.upload.clearFiles()
    },
    InBeforeU() {   // 新数据 上传成功前
      loading = Loading.service({fullscreen: true})
    },
    InAfterU(res) {    // 新数据 上传成功后
      loading.close()
      this.$refs.upload.clearFiles()
      this.ClearSub('insTable')
      if (res.code === 'OK') {
        this.$notify({
          type: 'success',
          title: '上传成功',
          message: res.msg,
        })
        this.step1 = false
        this.step2 = true
        this.insConfirm = res
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
        })
      }
    },
    InPageChg(e) {    // 新数据 翻页
      let page = ~~e
      page--
      const token = this.token
      getExCache({
        token,
        page,
        fullPath: this.insConfirm.fullPath,
        filePath: this.insConfirm.filePath,
        uploadTime: this.insConfirm.uploadTime,
        screen: this.subScreen,
      })
        .then(res => {
          if (res.data.code === 'OK') this.insConfirm = res.data
        })
        .catch(err => console.log(err))
    },
    InDo() {    // 新数据 确认导入
      exDoIns({token: this.token})
        .then(res => {
          if (res.data.code === 'OK') {
            this.$message({
              type: 'success',
              message: '导入成功',
            })
            this.insConfirm.data = []
            this.inLayer = this.step2 = this.step3 = false
            this.step1 = true
            this.GetExSku()
          }
        })
        .catch(err => console.log(err))
    },
    InCl() {    // 新数据 取消
      this.inLayer = this.buyInLayer = this.step2 = this.step3 = false
      this.step1 = true
      if (this.insConfirm.filePath && this.insConfirm.fullPath && this.insConfirm.uploadTime) {
        cancelExIns({
          token: this.token,
          fullPath: this.insConfirm.fullPath,
          filePath: this.insConfirm.filePath,
          uploadTime: this.insConfirm.uploadTime,
        })
          .then(res => {
            if (res.data.code === 'OK') {
              this.insConfirm.data = []
              this.inLayer = this.step2 = this.step3 = false
              this.step1 = true
            }
          })
          .catch(err => console.log(err))
      }
    },
    /** 导出功能 **/
    Output() {    // 外延片数据导出
      this.$confirm('确定导出符合当前条件的外延片数据吗？')
        .then(res => {
          const token = this.token
          formRequest(baseUrl + '/ex-output', {
            token,
            colScreen: JSON.stringify(this.colScreen),
          })
        })
        .catch(err => console.log(err))
    },
    /** 覆盖导入 **/
    RwLayer() {   // 覆盖 上传弹窗
      if (this.$refs.rwUpload) this.$refs.rwUpload.clearFiles()
      this.rwLayer = this.step1 = true
      this.step2 = this.step3 = false
    },
    RwBeforeU() {   // 覆盖 上传成功前
      loading = Loading.service({fullscreen: true})
    },
    RwAfterU(res) {    // 覆盖 上传成功后
      loading.close()
      this.$refs.rwUpload.clearFiles()
      this.ClearSub('rwTable')
      if (res.code === 'OK') {
        this.$notify({
          type: 'success',
          title: '上传成功',
          message: res.msg,
        })
        this.step1 = this.step3 = false
        this.step2 = true
        this.rwOldData = res.rs
        this.rwNewData = this.rwConfirm.data = res.rw
        this.rwConfirm.fullPath = res.fullPath
        this.rwConfirm.filePath = res.filePath
        this.rwConfirm.uploadTime = res.uploadTime
      } else {
        this.$message({
          type: 'error',
          message: res.msg,
        })
      }
    },
    RwDo() {      // 覆盖 执行覆盖
      const token = this.token
      exRewriteDo({token, fullPath: this.rwConfirm.fullPath})
        .then(res => {
          if (res.data.code === 'OK') {
            this.rwLayer = this.step2 = this.step3 = false
            this.step1 = true
            this.rwConfirm.data = []
            this.$message({
              type: 'success',
              message: res.data.msg,
            })
            this.GetExSku(this.page - 1)
          }
        })
        .catch(err => console.log(err))
    },
    RwCl() {      // 覆盖 取消
      this.rwLayer = this.step2 = this.step3 = false
      this.step1 = true
      if (this.rwConfirm.filePath && this.rwConfirm.fullPath && this.rwConfirm.uploadTime) {
        exRewriteCl({
          token: this.token,
          filePath: this.rwConfirm.filePath,
          uploadTime: this.rwConfirm.uploadTime,
          fullPath: this.rwConfirm.fullPath,
        })
          .then(res => {
            if (res.data.code === 'OK') {
              this.rwLayer = this.step2 = this.step3 = false
              this.step1 = true
              this.rwConfirm.data = []
            }
          })
          .catch(err => console.log(err))
      }
    },
    RwON() {    // 覆盖 新旧数据切换
      this.rwConfirm.data === this.rwNewData
        ? this.rwConfirm.data = this.rwOldData
        : this.rwConfirm.data = this.rwNewData
    },
    /** 批量导入修改 **/
    BatLayer() {      // 批量修改 上传弹窗
      if (this.$refs.uploadBat) this.$refs.uploadBat.clearFiles()
      this.batLayer = this.step1 = true
      this.step2 = this.step3 = false
    },
    BatBeforeU() {    // 批量修改 上传成功前
      loading = Loading.service({fullscreen: true})
    },
    BatAfterU(res) {     // 批量修改 上传成功后
      loading.close()
      this.$refs.uploadBat.clearFiles()
      this.ClearSub('batTable')
      if (res.code === 'OK') {
        this.step1 = false
        this.step2 = true
        this.batConfirm = res
        this.$notify({
          title: '上传成功',
          type: 'success',
          message: `共匹配 ${res.count} 条`,
        })
      } else {
        this.$message({
          type: 'error',
          message: res.msg
        })
      }
    },
    BatPageChg(e) {   // 批量修改 翻页
      let page = e
      page--
      exGetBat({
        token: this.token,
        screen: this.subScreen,
        page,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.batConfirm = res.data
          }
        })
        .catch(err => console.log(err))
    },
    BatNext() {   // 批量修改 下一步
      this.step1 = this.step2 = false
      this.step3 = true
    },
    BatDo() {     // 批量修改 执行
      this.$refs.batForm.EmitFormData()
      exBatDo({
        token: this.token,
        fullPath: this.batConfirm.fullPath,
        form: this.batFormData,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.batLayer = this.step2 = this.step3 = false
            this.step1 = true
            this.batConfirm.data = []
            this.batFormData = {}
            this.$message({
              type: 'success',
              message: '批量修改成功'
            })
            this.GetExSku(this.page - 1)
          }
        })
        .catch(err => console.log(err))
    },
    BatCl() {     // 批量修改 取消
      this.batLayer = this.step3 = this.step2 = false
      this.step1 = true
      exBatCl({
        token: this.token,
        data: this.batConfirm,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.batLayer = this.step2 = this.step3 = false
            this.step1 = true
            this.batConfirm.data = []
            this.batFormData = this.$refs.batForm.formData = {}
          }
        })
        .catch(err => console.log(err))
    },
    /** 批量选中 **/
    BsLayer(save) {   // 选中 弹窗
      if (!save) this.ClearSub('bsTable')
      exGetBs({
        token: this.token,
        ids: this.selectedIds,
        screen: this.subScreen,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.bsConfirm = res.data.data
            this.bsLayer = this.step1 = true
            this.step2 = this.step3 = false
          }
        })
        .catch(err => console.log(err))
    },
    BsNext() {      // 选中 - 修改 下一步
      this.step1 = false
      this.step2 = true
    },
    BsDo() {        // 选中 - 修改 执行
      this.$refs.bsForm.EmitFormData()
      exBsDo({
        token: this.token,
        ids: this.selectedIds,
        form: this.bsFormData,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.bsConfirm = []
            this.step2 = this.step3 = this.bsLayer = false
            this.step1 = true
            this.$message({
              type: 'success',
              message: '批量修改成功',
            })
            this.GetExSku(this.page - 1)
          }
        })
        .catch(err => console.log(err))
    },
    BsCl() {    // 选中 - 取消
      this.bsLayer = this.step2 = this.step3 = false
      this.step1 = true
      this.bsConfirm = []
      this.bsFormData = this.$refs.bsForm.formData = {}
    },
    /** 删除 **/
    DelLayer(save) {    // 删除  显示确认表格
      if (!save) this.ClearSub('delTable')
      exGetBs({
        token: this.token,
        ids: this.selectedIds,
        screen: this.subScreen,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.bsConfirm = res.data.data
            this.delLayer = this.step1 = true
            this.step2 = this.step3 = false
          }
        })
        .catch(err => console.log(err))
    },
    DelDo() {
      exBsDel({
        token: this.token,
        ids: this.selectedIds,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.delLayer = this.step2 = this.step3 = false
            this.step1 = true
            this.bsConfirm = []
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.GetExSku(this.page - 1)
          }
        })
        .catch(err => console.log(err))
    },
    DelCl() {
      this.delLayer = this.step2 = this.step3 = false
      this.step1 = true
      this.bsConfirm = []
    },
    /**  全选本页  **/
    AllPage() {
      let ids = []
      this.extSkuData.data.forEach(item => ids.push(item.id))
      this.selectedIds = ids
    },
    /** 取消已选 **/
    BsClear() {
      this.selectedIds = []
    },
    /** 清空筛选项 **/
    ClearAll() {
      this.colScreen = {}
    },
    /** 获取子组件发射过来的筛选项 **/
    GetSubScreen(screen) {
      console.log(screen)
      this.subScreen = screen
      //*********************
      // 根据不同的 myName 执行不同的动作  获取对应的筛选数据
      if (screen.myName === 'insTable') this.InPageChg(1)
      if (screen.myName === 'batTable') this.BatPageChg(1)
      if (screen.myName === 'bsTable') this.BsLayer(true)
      if (screen.myName === 'delTable') this.DelLayer(true)
    },
    /** 获取子组件发射过来的表单数据 **/
    GetFormData(formData) {
      if (formData.formName === 'bat') this.batFormData = formData
      if (formData.formName === 'bs') this.bsFormData = formData
      if (formData.formName === 'oneRow') this.whichEdit = formData
      if (formData.formName === 'buy') this.buyForm = formData
    },
    /* 清空子组件筛选项 */
    ClearSub(which) {
      this.subScreen = {}
      if (this.$refs[which]) this.$refs[which].colScreen = {}
    },
    // 主表格 行右击事件
    RightClick(row, column, event) {
      this.whichRightData = JSON.parse(JSON.stringify(row))
      this.menuVisible = false  // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true   // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault()    //关闭浏览器右键默认事件
      const menu = document.querySelector('.menu');
      this.StyleMenu(menu)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      document.removeEventListener('click', this.foo) // 关掉监听，
    },
    // 主表格 右键菜单样式
    StyleMenu(menu) {
      let e = event || window.event
      let X = e.clientX
      let Y = e.clientY
      if (X > 1800) {
        menu.style.left = X - 100 + 'px'
      } else {
        menu.style.left = X + 1 + 'px'
      }
      document.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      if (Y > 700) {
        menu.style.top = Y - 30 + 'px'
      } else {
        menu.style.top = Y - 10 + 'px'
      }
    },
    // 主表格 右键菜单取消
    Cancel() {
      console.log('取消')
    },
    RCEditRow() {
      this.RowDblClick(this.whichRightData)
    },
    ShowLogs() {
      const Wafer_ID = this.whichRightData.Wafer_ID
      getExLogs({
        token: this.token,
        Wafer_ID,
      })
        .then(res => {
          this.exLogs = true
          let 日志 = []
          res.data.rs.forEach(row => {
            const 当前日志 = JSON.parse(`[${row.日志}]`)
            日志 = 日志.concat(当前日志)
          })
          this.showExLogs = 日志.reverse()
        })
        .catch(err => console.log(err))
    },
    // 外购相关
    BuyIn() {
      this.buyInLayer = this.step1 = true
      this.step2 = this.step3 = false
    },
    IBBefore(file) {
      loading = Loading.service({fullscreen: true})
    },
    IBover(res) {
      loading.close()
      if (res.code === 'OK') {
        this.insConfirm = res
        this.step1 = false
        this.step2 = true
      }
    },
    IBDo() {
      this.buyForm = {生产类型: '外购'}
      this.$refs.bForm.EmitFormData()
      insBuyEpi({
        token: this.token,
        fullPath: this.insConfirm.fullPath,
        form: this.buyForm,
      })
        .then(res => {
          if (res.data.code === 'OK') {
            this.buyForm = {生产类型: '外购'}
            this.buyInLayer = this.step1 = this.step2 = this.step3 = false
            this.$message.success('导入成功')
            this.GetExSku()
          }
        })
        .catch(err => console.log(err))
    },
  },
  computed: {
    GetRowClassName() {
      return ({row}) => {
        const ids = this.selectedIds
        const idx = ids.findIndex(item => item === row.id)
        if (idx !== -1) return 'warning-row'
      }
    },
    GetDate() {
      return date => date ? moment(date).format('YYYY/MM/DD') : ''
    },
  },
  activated() {
    this.GetExSku()
    document.title = '外延片库存管理 - ' + baseTitle
  }
}
</script>

<style>
.el-table .warning-row {
  background: yellow !important;
  color: red !important;
  font-weight: bold;
}

/deep/ .el-table td, .el-table th {
  padding: 8px 0;
}

.contextmenu__item {
  display: block;
  line-height: 34px;
  text-align: center;
}

.contextmenu__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu {
  position: fixed;
  background-color: #fff;
  width: 100px;
  /*height: 106px;*/
  font-size: 12px;
  color: #444040;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  z-index: 1000;
}

.contextmenu__item:hover {
  cursor: pointer;
  background: #66b1ff;
  border-color: #66b1ff;
  color: #fff;
}
</style>
